import $ from 'jquery'
$(document).ready( initAnimatedSlider)

function initAnimatedSlider(){
  if ($('.js-animated-slider').length) {

    slickScrollSlider();

    // Initial onload function
    // if (window.innerWidth > 960) slickScrollSlider();
    // else initMobileSlick();

    // FN for screen resizing
    // var slickScrollActive = false;
    // var slickMobileActive = false;
    // window.onresize = function() {
    //   if (window.innerWidth > 960) { 
    //     if(!slickScrollActive) {
    //       slickScrollSlider();
    //       slickScrollActive = true;
    //       slickMobileActive = false;
    //     }
    //   }
    //   if (window.innerWidth <= 960) { 
    //     if(!slickMobileActive) {
    //       initMobileSlick();
    //       slickMobileActive = true;
    //       slickScrollActive = false;
    //     }
    //   }
    // }
  }
}

// function initMobileSlick(){
//   $('.js-animated-slider').not('.slick-initialized').slick({
//     arrows: false,
//     dots: true,
//     fade: true,
//     cssEase: 'linear',
//     autoplay: true,
//     autoplaySpeed: 5000,
//     mobileFirst: true,
//     responsive: [
//       {
//         breakpoint: 960,
//         settings: "unslick"
//       }
//     ]
//   });
// }

function slickScrollSlider(){
  function debounce(func, wait, immediate) {
    var timeout;
    return function() {
      var context = this, args = arguments;
      var later = function() {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      var callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  };
  function slick_handle_wheel_event(e, slick_instance, slick_is_animating) {
    if (!slick_is_animating) {
      var direction =
      Math.abs(e.deltaX) > Math.abs(e.deltaY) ? e.deltaX : e.deltaY;
      console.log("wheel scroll ", e.deltaX, e.deltaY, direction);
      console.log('slick animated');
      
      if (direction > 0) {
        slick_instance.slick("slickNext");
      } else {
        slick_instance.slick("slickPrev");
      }
    }
  }
  // debounce the wheel event handling since trackpads can have a lot of inertia
  var slick_handle_wheel_event_debounced = debounce( 
    slick_handle_wheel_event
    , 200, true
  );

  // slider
  const slick_1 = $(".js-animated-slider");
  $(".js-animated-slider").not('.slick-initialized').slick({
    dots: true,
    buttons: false,
    arrows: false,
    infinite: false,
    // responsive: [
    //   {
    //     breakpoint: 960,
    //     settings: "unslick"
    //   }
    // ]
  });
  var slick_1_is_animating = false;
  slick_1.on("afterChange", function(index) {
    console.log("Slide settle " + index);
    slick_1_is_animating = false;
    $("html").removeClass('freeze');
  });
  slick_1.on("beforeChange", function(index) {
    console.log("Slide selected " + index);
    slick_1_is_animating = true;
    $("html").addClass('freeze');
  });
  slick_1.on("wheel", function(e) {
    slick_handle_wheel_event_debounced(e.originalEvent, slick_1, slick_1_is_animating);
  });
}